import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Rondorf: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Rondorf,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Rondorf"
          d="M496,951.1L442,934l0,0c-1.6-5.7-6.1-11-8.6-16.3l0.1-0.2c-3.7-7.2-9.6-15.8-13-23.1l0.4,0.1 c-0.5-4.2-1.7-9.9-1.6-14.2c1.2-3.7,2.9-7.7-1.9-9.4c3.6-11.2,10.3-22.9,14.5-33.8c7.5,4.5,45.5,26.9,52.1,30.9c0,0,26,6,26,6 s45.4-11.8,45.4-11.8c0.3,9.9,5.5,80.4,6.6,91.8l-36-16l-2,22L496,951.1z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 465.7679 908.0639)"
        >
          Rondorf
        </text>
      </g>
    </>
  );
});
